import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/Layout"
import measurements from "../styles/measurements"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

let maxHeight = 97

const Img = styled(Image)`
  width: 100%;
  height: auto;
  max-height: ${maxHeight}vh;
  margin-top: -2.5rem;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    margin-top: 0rem;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHomepageImage {
        edges {
          node {
            image {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  let images = data.allContentfulHomepageImage.edges
  let selectedImage = images[Math.floor(Math.random() * images.length)]
  let aspectRatio = selectedImage.node.image.fluid.aspectRatio

  console.log(images, selectedImage)

  return (
    <Layout title="home">
      <Wrapper>
        <Img
          fluid={selectedImage.node.image.fluid}
          style={{ maxWidth: `${maxHeight * aspectRatio}vh` }}
        ></Img>
      </Wrapper>
    </Layout>
  )
}
